var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"questionnaire"},[_c('van-nav-bar',{attrs:{"title":_vm.baseinfo.qsname}}),_c('div',{staticClass:"banner"}),(_vm.ispost)?_c('div',{staticClass:"toExaminedWjuan",staticStyle:{"position":"absolute","right":"15px","top":"70px","z-index":"999"}},[_c('img',{staticStyle:{"width":"75px"},attrs:{"src":require("@assets/img/toExamined-end.png")}})]):_vm._e(),(_vm.ispost)?_c('Result',{ref:"result",attrs:{"rsid":_vm.rsid}}):_c('div',[_vm._l((_vm.qsTiKu),function(bigType,qtIdx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(bigType.opt.length > 0),expression:"bigType.opt.length > 0"}],key:qtIdx,staticClass:"content"},[(bigType.opt.length > 0)?_c('div',{staticClass:"qsType"},[_c('span',{staticClass:"typeName"},[_vm._v(_vm._s(bigType.type))])]):_vm._e(),(bigType.opt.length > 0)?_c('div',_vm._l((bigType.opt),function(item,index){return _c('div',{key:index,staticClass:"qsContent"},[_c('div',{staticClass:"qsTit",class:[
              _vm.isClick == true ? 'clickCls' : '',
              typeof item.answer == 'number'
                ? item.answer == 0
                  ? 'tips'
                  : ''
                : typeof item.answer == 'string'
                ? item.answer != ''
                  ? ''
                  : 'tips'
                : item.answer.length > 0
                ? ''
                : 'tips' ]},[_vm._v(" "+_vm._s(((index + 1) + "." + (item.title)))+" "),(item.select_count > 0 && item.select_count != item.answer.length && bigType.type == '多选题')?_c('strong',{staticStyle:{"color":"red"}},[_vm._v(" (该题必须"+_vm._s(item.select_count)+"个答案) ")]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.picurl),expression:"item.picurl"}],staticClass:"title_pic"},[_c('van-image',{attrs:{"fit":"contain","src":item.picurl ? _vm.filesvrurl + '/' + item.picurl + '.png' : ''},on:{"click":function($event){return _vm.imagePreview(_vm.filesvrurl + '/' + item.picurl + '.png')}}})],1),(bigType.type == '单选题')?_c('van-radio-group',{staticClass:"singleChoice",model:{value:(item.answer),callback:function ($$v) {_vm.$set(item, "answer", $$v)},expression:"item.answer"}},_vm._l((item.options),function(opt,opIdx){return _c('div',{key:opIdx},[_c('van-radio',{attrs:{"name":_vm.abList[opIdx]}},[_c('span',{staticClass:"iico"},[_vm._v(_vm._s(_vm.abList[opIdx]))]),_vm._v(" "+_vm._s(opt)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.pics[opIdx]),expression:"item.pics[opIdx]"}],staticClass:"option_pic"},[_c('van-image',{attrs:{"width":"100%","height":"100%","src":item.pics[opIdx]
                      ? _vm.filesvrurl + '/' + item.pics[opIdx] + '.png'
                      : ''},on:{"click":function($event){return _vm.imagePreview(_vm.filesvrurl + '/' + item.pics[opIdx] + '.png')}}})],1)],1)}),0):(bigType.type == '多选题')?_c('van-checkbox-group',{model:{value:(item.answer),callback:function ($$v) {_vm.$set(item, "answer", $$v)},expression:"item.answer"}},_vm._l((item.options),function(opt,opIdx){return _c('div',{key:opIdx},[_c('van-checkbox',{attrs:{"name":_vm.abList[opIdx]}},[_c('span',{staticClass:"iico"},[_vm._v(_vm._s(_vm.abList[opIdx]))]),_vm._v(" "+_vm._s(opt)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.pics[opIdx]),expression:"item.pics[opIdx]"}],staticClass:"option_pic"},[_c('van-image',{attrs:{"width":"100%","height":"100%","src":item.pics[opIdx]
                      ? _vm.filesvrurl + '/' + item.pics[opIdx] + '.png'
                      : ''},on:{"click":function($event){return _vm.imagePreview(_vm.filesvrurl + '/' + item.pics[opIdx] + '.png')}}})],1)],1)}),0):_vm._e(),(bigType.type == '简答题')?_c('van-field',{staticClass:"brief",attrs:{"rows":"10","autosize":"","label":"","type":"textarea","placeholder":"请输入答案","show-word-limit":""},model:{value:(item.answer),callback:function ($$v) {_vm.$set(item, "answer", $$v)},expression:"item.answer"}}):_vm._e(),(bigType.type == '附件题')?_c('van-uploader',{attrs:{"multiple":"","max-count":1},model:{value:(item.answer),callback:function ($$v) {_vm.$set(item, "answer", $$v)},expression:"item.answer"}},[_c('van-button',{attrs:{"icon":"plus","type":"info"}},[_vm._v("上传文件")])],1):_vm._e(),(bigType.type == '打分题')?_c('div',{staticStyle:{"display":"flex","align-items":"center","flex-wrap":"wrap"}},[_c('van-rate',{attrs:{"count":item.options.length},model:{value:(item.answer),callback:function ($$v) {_vm.$set(item, "answer", $$v)},expression:"item.answer"}}),_c('div',{staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(item.options[item.answer - 1])+" ")])],1):_vm._e()],1)}),0):_vm._e()])}),(_vm.loadingData)?_c('div',{staticClass:"footer"},[_c('van-button',{attrs:{"type":"info"},on:{"click":_vm.submitPaper}},[_vm._v("提交测评")])],1):_vm._e()],2),_c('submitPrompt',{ref:"submitPrompt",attrs:{"noAnswer":_vm.noAnswer,"qsTotalNum":_vm.ddjfdTiKu.length,"qsTiKu":_vm.qsTiKu,"rsid":_vm.rsid,"paperid":_vm.paperid},on:{"submited":_vm.submited}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }