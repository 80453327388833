<template>
  <!-- 评价课程问卷 -->
  <div class="questionnaire">
    <van-nav-bar :title="baseinfo.qsname" />
    <div class="banner"></div>
    <div
      v-if="ispost"
      class="toExaminedWjuan"
      style="position: absolute; right: 15px; top: 70px; z-index: 999"
    >
      <img style="width: 75px" src="@assets/img/toExamined-end.png" />
    </div>
    <Result v-if="ispost" ref="result" :rsid="rsid"></Result>
    <div v-else>
      <div
        class="content"
        v-for="(bigType, qtIdx) in qsTiKu"
        :key="qtIdx"
        v-show="bigType.opt.length > 0"
      >
        <!-- 题型信息 -->
        <div class="qsType" v-if="bigType.opt.length > 0">
          <span class="typeName">{{ bigType.type }}</span>
        </div>
        <!-- 题目内容详情 -->
        <div v-if="bigType.opt.length > 0">
          <div
            class="qsContent"
            v-for="(item, index) in bigType.opt"
            :key="index"
          >
            <div
              class="qsTit"
              :class="[
                isClick == true ? 'clickCls' : '',
                typeof item.answer == 'number'
                  ? item.answer == 0
                    ? 'tips'
                    : ''
                  : typeof item.answer == 'string'
                  ? item.answer != ''
                    ? ''
                    : 'tips'
                  : item.answer.length > 0
                  ? ''
                  : 'tips',
              ]"
            >
              {{ `${index + 1}.${item.title}` }}
              <strong style="color:red;" v-if="item.select_count > 0 && item.select_count != item.answer.length && bigType.type == '多选题'">
                (该题必须{{item.select_count}}个答案)
              </strong>
            </div>
            <div class="title_pic" v-show="item.picurl">
              <van-image
                fit="contain"
                :src="
                  item.picurl ? filesvrurl + '/' + item.picurl + '.png' : ''
                "
                @click="imagePreview(filesvrurl + '/' + item.picurl + '.png')"
              />
            </div>
            <!-- 单选题 -->
            <van-radio-group
              class="singleChoice"
              v-model="item.answer"
              v-if="bigType.type == '单选题'"
            >
              <div v-for="(opt, opIdx) in item.options" :key="opIdx">
                <van-radio :name="abList[opIdx]">
                  <span class="iico">{{ abList[opIdx] }}</span>
                  {{ opt }}
                </van-radio>
                <div class="option_pic" v-show="item.pics[opIdx]">
                  <van-image
                    width="100%"
                    height="100%"
                    :src="
                      item.pics[opIdx]
                        ? filesvrurl + '/' + item.pics[opIdx] + '.png'
                        : ''
                    "
                    @click="
                      imagePreview(filesvrurl + '/' + item.pics[opIdx] + '.png')
                    "
                  />
                </div>
              </div>
            </van-radio-group>
            <!-- 多选题 -->
            <van-checkbox-group
              v-model="item.answer"
              v-else-if="bigType.type == '多选题'"
            >
              <div v-for="(opt, opIdx) in item.options" :key="opIdx">
                <van-checkbox :name="abList[opIdx]">
                  <span class="iico">{{ abList[opIdx] }}</span>
                  {{ opt }}
                </van-checkbox>
                <div class="option_pic" v-show="item.pics[opIdx]">
                  <van-image
                    width="100%"
                    height="100%"
                    :src="
                      item.pics[opIdx]
                        ? filesvrurl + '/' + item.pics[opIdx] + '.png'
                        : ''
                    "
                    @click="
                      imagePreview(filesvrurl + '/' + item.pics[opIdx] + '.png')
                    "
                  />
                </div>
              </div>
            </van-checkbox-group>
            <!-- 简答题 -->
            <van-field
              class="brief"
              v-if="bigType.type == '简答题'"
              v-model="item.answer"
              rows="10"
              autosize
              label=""
              type="textarea"
              placeholder="请输入答案"
              show-word-limit
            />
            <!-- 附近题 -->
            <van-uploader
              v-if="bigType.type == '附件题'"
              v-model="item.answer"
              multiple
              :max-count="1"
            >
              <van-button icon="plus" type="info">上传文件</van-button>
            </van-uploader>
            <!-- 打分题 -->
            <!-- <van-rate v-if="bigType.type == '打分题'"
                    v-model="item.answer" /> -->
            <div
              v-if="bigType.type == '打分题'"
              style="display: flex; align-items: center; flex-wrap: wrap"
            >
              <van-rate :count="item.options.length" v-model="item.answer" />
              <div style="margin-left: 10px">
                {{ item.options[item.answer - 1] }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer" v-if="loadingData">
        <van-button type="info" @click="submitPaper">提交测评</van-button>
      </div>
    </div>
    <submitPrompt
      ref="submitPrompt"
      :noAnswer="noAnswer"
      :qsTotalNum="ddjfdTiKu.length"
      :qsTiKu="qsTiKu"
      :rsid="rsid"
      :paperid="paperid"
      @submited="submited"
    />
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import submitPrompt from "./submitPrompt.vue";
import { getIvQtOptionList } from "@api/wxzp.js";
import Result from "./result";

export default {
  components: { Result, submitPrompt },
  data() {
    return {
      baseinfo: {},
      answerData: [],
      // 题号
      bigOrder: ["一、", "二、", "三、", "四、", "五、"],
      // 题目类型
      qsTypeArr: ["单选题", "多选题", "简答题", "附件题", "打分题"],
      // 题库
      qsTiKu: [
        {
          type: "单选题",
          opt: [],
        },
        {
          type: "多选题",
          opt: [],
        },
        {
          type: "简答题",
          opt: [],
        },
        {
          type: "附件题",
          opt: [],
        },
        {
          type: "打分题",
          opt: [],
        },
      ],
      // 除了备注和分割线的题目
      ddjfdTiKu: [],
      abList: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      isClick: false,
      loadingData: false,
      rsid: 0,
      paperid: 0,
      filesvrurl: "", // 图片前缀
      ispost: false, // 是否已提交
    };
  },
  computed: {
    // 计算试卷有多少题没有答案
    noAnswer() {
      let num = 0;
      if (this.qsTiKu.length > 0) {
        this.qsTiKu.forEach((qs, idx) => {
          if (qs.type == "单选题") {
            let ary = qs.opt.filter((t, i) => {
              return t.answer == "";
            });
            num += ary.length;
          } else if (qs.type == "多选题") {
            let ary = qs.opt.filter((t, i) => {
              return t.answer.length == 0;
            });
            num += ary.length;
          } else if (qs.type == "简答题") {
            let ary = qs.opt.filter((t, i) => {
              return t.answer == "";
            });
            num += ary.length;
          } else if (qs.type == "附件题") {
            let ary = qs.opt.filter((t, i) => {
              return t.answer.length == 0;
            });
            num += ary.length;
          } else if (qs.type == "打分题") {
            let ary = qs.opt.filter((t, i) => {
              return t.answer == 0;
            });
            num += ary.length;
          }
        });
      }
      return num;
    },
  },
  created() {
    this.getIvQtOptionListData();
    window.T = this;
    this.rsid = parseInt(this.$route.query.rsid);
  },
  methods: {
    // 提交完刷新下页面
    submited() {
      this.getIvQtOptionListData();
    },
    // 预览图片
    imagePreview(val) {
      ImagePreview([val]);
    },
    getIvQtOptionListData() {
      getIvQtOptionList({
        rsid: this.$route.query.rsid,
        stationid: this.$route.query.stationid,
      }).then((res) => {
        if (res.iserror == 0) {
          this.baseinfo = res.baseinfo[0];
          this.filesvrurl = res.baseinfo[0].filesvrurl;
          if (res.baseinfo.length == 0) {
            alert("考题基本信息还没设置，请设置！");
            return false;
          }
          if (res.data.length == 0) {
            alert("考题为空，请设置！");
            return false;
          }
          this.ispost = res.baseinfo[0].ispost;
          this.paperid = this.baseinfo.paperid;
          this.answerData = res.data;
          this.ddjfdTiKu = [];
          this.answerData.forEach((item) => {
            if (item.opflag != 5 && item.opflag != 6) {
              this.ddjfdTiKu.push(item);
            }
          });
          this.qsTiKu.forEach((value, key) => {
            value.opt = [];
            this.answerData.forEach((item) => {
              let type = this.qsTypeArr[item.opflag];
              if (type == value.type) {
                if (type == "附件题" || type == "多选题") {
                  let obj = {};
                  obj.title = item.optitle;
                  obj.picurl = item.picurl;
                  obj.select_count = item.select_count;
                  obj.pics = item.itemurl ? item.itemurl.split(",") : [];
                  obj.tips = "";
                  obj.answer = [];
                  obj.options = item.oplist.split("|");
                  obj.autoid = item.autoid;
                  value.opt.push(obj);
                } else if (type == "打分题") {
                  let obj = {};
                  obj.title = item.optitle;
                  obj.picurl = item.picurl;
                  obj.tips = "";
                  obj.answer = 0;
                  obj.options = item.oplist.split("|");
                  obj.autoid = item.autoid;
                  obj.select_count = item.select_count;
                  value.opt.push(obj);
                } else {
                  let obj = {};
                  obj.title = item.optitle;
                  obj.picurl = item.picurl;
                  obj.pics = item.itemurl ? item.itemurl.split(",") : [];
                  obj.tips = "";
                  obj.answer = "";
                  obj.options = item.oplist.split("|");
                  obj.autoid = item.autoid;
                  obj.select_count = item.select_count;
                  value.opt.push(obj);
                }
              }
            });
          });
          console.log(this.qsTiKu,'qsTiKuqsTiKu')
          this.loadingData = true;
        }
      });
    },
    // 提交试卷
    submitPaper() {
      this.isClick = true;
      this.$refs.submitPrompt.show = true;
    },
    // 点击返回
    onClickLeft() {
      if (this.noAnswer > 0) {
        this.$refs.exitPrompt.show = true;
      } else {
        this.$router.push("/toEvaluated");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.questionnaire {
  background: #fff;
  height: 100vh;

  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .banner {
    height: 124px;
    background: url("~@/assets/img/examination1.png") no-repeat;
    background-size: cover;
    background-position-y: -150px;
  }
  .content {
    font-size: 36px;
    font-family: Source Han Sans SC;
    padding: 30px 0;
    border-bottom: 1px solid #ccc;
    background: #fff;
    .qsType {
      margin-bottom: 28px;
      border-bottom: 1px solid #ccc;
      padding: 0 30px;
      padding-bottom: 28px;
      .typeName {
        margin-right: 15px;
        font-weight: bold;
      }
      .grade {
        color: #2b8df0;
      }
    }
    .qsContent {
      padding: 36px 0;
      padding: 0 30px;
      color: #333;
      font-size: 32px;
      margin-bottom: 30px;
      .qsTit {
        margin-bottom: 42px;
      }
      .title_pic {
        margin-bottom: 20px;
        .van-image {
          border: 1px solid #e1e6f0;
        }
      }
      .option_pic {
        margin-bottom: 20px;
        .van-image {
          border: 1px solid #e1e6f0;
        }
      }
      // 单选样式
      .singleChoice {
        padding-left: 1em;
        .van-radio {
          margin-bottom: 40px;
        }
        .van-radio {
          /deep/ .van-radio__icon--round {
            display: none;
          }
          /deep/.van-radio__label {
            margin-left: 0px;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-radio[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 多选样式
      .van-checkbox-group {
        padding-left: 1em;
        .van-checkbox {
          margin-bottom: 40px;
        }
        .van-checkbox {
          /deep/ .van-checkbox__icon--round {
            display: none;
          }
          /deep/.van-checkbox__label {
            margin-left: 0px;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-checkbox[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-checkbox__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 判断题样式
      .judge {
        padding-left: 1em;
        .van-radio {
          margin-bottom: 40px;
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 填空题样式
      .tk {
        div {
          .van-cell {
            /deep/.van-cell__title {
              width: 4em;
              margin-right: 0;
            }
          }
          .zqdn {
            /deep/.van-cell__title {
              width: 5.3em;
              margin-right: 0;
            }
          }
        }
      }
      // 简答题样式
      .brief {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
      }
      // 上传题样式
      /deep/.van-uploader {
        width: 100%;
        /deep/.van-uploader__wrapper {
          display: flex;
          justify-content: flex-end;
          flex-flow: row-reverse;
          // flex-direction: column-reverse;
          /deep/.van-uploader__input-wrapper {
            // background: red;
            width: 50%;
            // width:80%;
          }
        }
      }
      // 评分样式
      .score {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px;
        background: #e6e6e6;
        border-radius: 42px;
        padding: 0 15px;
        font-size: 32px;
        margin-bottom: 16px;
        span:first-child {
          display: flex;
          align-items: center;
        }
        .svg-icon {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
      // 答案解析样式
      .analysis {
        background: #e6e6e6;
        border-radius: 42px;
        padding: 30px;
        font-size: 32px;
        font-family: Source Han Sans SC;
        color: #333;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    // padding-bottom: 20px;
    height: 186px;
    line-height: 186px;
    .van-button {
      width: 80%;
    }
  }
  .clickCls.tips {
    color: red !important;
  }
}
</style>
